
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        




































































































































































































































































































































/* stylelint-disable declaration-no-important */
.contact-vfe-popup,
[class*='contact-vfe-popup--'] {
  @extend %box-shadow;

  overflow: auto;
  width: 90vw;
  max-width: 69rem;
  max-height: 80vh;
  padding: $spacing * 1.5;
  background-color: $white;
  border-radius: $border-radius;

  .g-recaptcha {
    transform: translateY(40px);
  }

  @include mq(m) {
    padding: $spacing * 3;
  }
}

.contact-vfe-popup__back {
  position: absolute;
  top: 1rem;
  left: 0.5rem;
  padding: 1rem;
  background: transparent;
  border: 0;

  svg {
    width: 2rem;
    height: 2rem;
    transform: rotate(180deg);
  }

  @include mq(m) {
    top: 2rem;
    right: 2rem;
  }
}

.contact-vfe-popup-inner {
  position: relative;

  > * {
    overflow: hidden;
    padding: 0 $spacing / 2;
    transform: translateX(0);
  }
}

.contact-vfe-popup__form {
  .contact-vfe-popup__inputs__input {
    &:not(:last-child) {
      margin-bottom: $spacing / 2;
    }
  }
}

.contact-vfe-popup__form__title {
  margin: $spacing 0;
}

.contact-vfe-popup__form__subtitle {
  margin-bottom: $spacing;
}

.contact-vfe-popup__form__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing * 2;
  padding-bottom: $spacing;

  .grecaptcha-badge {
    position: relative !important;
    right: initial !important;
    bottom: initial !important;
    width: 7rem !important;
    box-shadow: none !important;
  }
}

// FORM
.contact-vfe-popup__form__errors,
.contact-vfe-popup__form__error {
  display: none;

  &.is-visible {
    display: block;
  }
}

.contact-vfe-popup__form__validation {
  @extend %fw-bold;

  color: $c-green-light;
}

.contact-vfe-popup__form__error {
  @extend %fw-bold;

  color: $c-error;
}
